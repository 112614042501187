import {BrowserPlugin, BrowserTracker} from "@snowplow/browser-tracker-core";
import {v4 as uuid} from "uuid";
import {base64encode} from "@snowplow/tracker-core/src/base64";

export const ipv6 = {
    ipv6: function (): BrowserPlugin {
        return {
            activateBrowserPlugin: (tracker: BrowserTracker) => {
                const pageViewSchema = {
                    "schema": "iglu:com.snowplowanalytics.snowplow/contexts/jsonschema/1-0-0",
                    "data": [{
                        "schema": "iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0",
                        "data": {"id": tracker.getPageViewId()}
                    }]
                }

                let userInfo = tracker.getDomainUserInfo();

                const data = {
                    schema: 'iglu:com.snowplowanalytics.snowplow/payload_data/jsonschema/1-0-4',
                    data: [{
                        "e": "se",
                        // "url": "https://www.duefotografi.nl/",
                        // "page": "Fotografen duo - Due Fotografi - Bruiloftfotografie door twee fotografen",
                        "eid": uuid(), //event_id
                        "tv": "ip-3.18.0", //tracker version
                        "tna": "cl1", //tracker name
                        //@ts-expect-error
                        "aid": window.leadinfo?.t,
                        "p": "web",
                        // "cookie": "1",
                        // "cs": "UTF-8",
                        // "lang": "en-GB",
                        // "res": "1920x1080",
                        // "cd": "24",
                        "dtm": String(new Date().getTime()), //device timestamp
                        //context: webpage id !important
                        "cx": base64encode(JSON.stringify(pageViewSchema)),
                        // "vp": "1920x934",
                        // "ds": "1905x3275",
                        //@ts-expect-error
                        "vid": String(userInfo[3]), //session_idx
                        //@ts-expect-error
                        "sid": userInfo[6], //session_id
                        "duid": uuid(), //domain user id (can be random i guess)
                        "stm": String(new Date().getTime()) //sent timestamp
                    }]
                }

                fetch("https://collector6.leadinfo.net/com.snowplowanalytics.snowplow/tp2", {
                    "headers": {
                        'Content-Type': "application/json"
                    },
                    "body": JSON.stringify(data),
                    "method": "POST",
                }).catch(() => {
                    //do nothing
                });
            },
        }
    }
}
